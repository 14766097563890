export const performCounter = (counterEl, duration) => {
	counterEl?.forEach(count => {
		const startNumber = parseInt(count.dataset.countStart);
		const endNumber = parseInt(count.dataset.countEnd);

		const start = Date.now(); // Remember start time
		const timer = setInterval(() => {
			const timePassed = Date.now() - start; // How much time passed from the start?

			if (timePassed >= duration) {
				clearInterval(timer); // Finish the animation after duration
				return;
			}

			count.innerText = parseInt((timePassed / (duration / (endNumber - startNumber))) + startNumber);
		}, 20);

		setTimeout(() => {
			count.innerText = endNumber
		}, duration);
	});
}